<template>
  <div class="appointmentList-view">
    <el-form v-model="form" :inline="true" label-width="100px">
      <el-row>
        <el-form-item label="表单标题">
          <el-input type="text" placeholder="请输入内容" v-model="form.title" style="width: 400px" maxlength="10"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="表单内容">
          <el-input type="textarea" rows="3" placeholder="请输入内容" v-model="form.content" maxlength="50" style="width: 400px"
            show-word-limit>
          </el-input>
        </el-form-item>
      </el-row>

      <publish-advanced-form ref="publishAdvancedForm" :initFormData="form.detailRequests" ></publish-advanced-form>
      <br>
      <el-row align="right" style="width: 800px; text-align: right">
        <el-button style="background-color: #51cdcb; color: white" @click="saveForm">发布</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {
  queryAdvancedFormById,
  addAdvancedForm,
  updateAdvancedForm,
} from "@/api/companyManage.js";
import publishAdvancedForm from "@/components/common/publishAdvancedForm";

export default {
  components:{
    publishAdvancedForm
  },
  name: "appointmentList",
  data() {
    return {
      actionURL: this.$store.state.ossUrl,
      dialogVisible: false,
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      checkList: [],
      disabled: true,
      type: "",
      form: {
        title: "",
        content: "",
        detailRequests: [
          {
            formType: "SINGLE",
            title:"单选项",
            required: true,
            childrenDetails: [
              { formType: "SINGLE", title: "选项1", content: "" },
              { formType: "SINGLE", title: "选项2", content: "" },
            ],
          },
          // {formType:'MULTIPLE',title:'多选一',childrenDetails:[{formType:'MULTIPLE',title:'多选一'},{formType:'MULTIPLE',title:'多选2'}]},
          // {formType:'DROP_DOWN',title:'DROP_DOWN',childrenDetails:[{formType:'DROP_DOWN',title:'DROP_DOWN一'},{formType:'DROP_DOWN',title:'DROP_DOWN2'}]},
          // {formType:'TEXTAREA',title:'TEXTAREA',childrenDetails:[{formType:'TEXTAREA',title:'TEXTAREA1'},{formType:'TEXTAREA',title:'TEXTAREA2'}]},
          // {formType:'SCOURE',title:'SCOURE',childrenDetails:[{formType:'SCOURE',title:'SCOURE1'},{formType:'SCOURE',title:'SCOURE2'}]},
          // {formType:'IMAGE',title:'IMAGE',childrenDetails:[{formType:'IMAGE',title:'IMAGE1'}]},
          // {formType:'GPS',title:'GPS',childrenDetails:[{formType:'GPS',title:'GPS1'}]},
          // {formType:'USERINFO',title:'USERINFO',childrenDetails:[{formType:'USERINFO',title:'USERINFO1'},{formType:'USERINFO',title:'USERINFO2'}]},
          // {formType:'DATEPICKER',title:'DATEPICKER',childrenDetails:[{formType:'DATEPICKER',title:'DATEPICKER1'}]}
        ],
      },
      checkedAll: false,
    };
  },
  created() {
    //检查是否编辑
    let advancedFormId = this.$route.query.id;
    if (advancedFormId) {
      //-console.log(advancedFormId);
      this.form.advancedFormId = advancedFormId
      this.type = "edit";
      this.getDetail(advancedFormId);
    }
  },
  mounted() { },
  methods: {
    //请求高级表单详情
    getDetail(id) {
      this.loading = true;
      let data = {
        advancedFormId: id,
      };
      queryAdvancedFormById(data)
        .then((res) => {
          this.loading = false;
          this.form = res.data;
        })
        .catch((err) => {
          this.loading = false;
          this.tableData = [];
          //-console.log(err);
        });
    },
    //保存表单
    saveForm() {
      this.form.detailRequests=this.$refs.publishAdvancedForm.detailRequests;
      this.form.detailRequests.forEach((item, index) => {
        item.serialNumber = index + 1;
        if (item.childrenDetails) {
          item.childrenDetails.forEach((child, ind) => {
            child.serialNumber = ind + 1;
          });
        }
        if (item.formType == "IMAGE") {
          item.childrenDetails.forEach((child, ind) => {
            child.serialNumber = ind + 1;
            //图片单独处理

            child.imgNum = child.title;
          });
        }
      });
      if ((this.type == "edit")) {
        this.form.isDraft = false;
        //编辑
        updateAdvancedForm(this.form).then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          //去列表
          this.$router.go(-1)
        });
      } else {
        //新增
        addAdvancedForm(this.form).then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.$router.go(-1)
        });
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}

.commentForm {
  border: 1px solid #dcdfe6;
  padding-top: 10px;
  margin-top: 10px;
}

.hover_pointer {
  transition: all .3s linear;
}

.hover_pointer:hover {
  transform: scale(1.2);
}
</style>